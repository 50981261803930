import 'vue-base-tooltip/style.css'
import './assets/main.scss'

import { createApp } from 'vue'
import VueLogger from 'vuejs3-logger'
import Toast, { POSITION, TYPE } from 'vue-toastification'
import VueBaseTooltip from 'vue-base-tooltip'

import App from './App.vue'
import axiosPlugin from './plugins/axios'
import auth0 from './plugins/auth0'
import { initSentry } from './plugins/sentry'
import router from './plugins/router'
import inputFilters from './plugins/input-filters'
import websocketPlugin from './plugins/websocket'
import { createI18n } from 'vue-i18n'
import fr from './locales/fr.js'
import axios from 'axios'
import * as Sentry from '@sentry/vue';

import ToasterClose from '@/components/toaster/ToasterClose.vue'

import 'vue-toastification/dist/index.css'
import { ILoggerOptions } from 'vuejs3-logger/dist/interfaces/logger-options'
import { LogLevels } from 'vuejs3-logger/dist/enum/log-levels'
import { ADD_IN_VERSION_KEY, APP_VERSION_KEY } from '@/data/localStorageKeys'
import { StoredVersion } from './types/version'

type MessageSchema = typeof fr

const loadingElement = document.getElementById('loading')
if (loadingElement && loadingElement.style) {
  loadingElement.style.display = 'flex'
}

const displayError = function () {
  const loadingElement = document.getElementById('loading')
  if (loadingElement && loadingElement.style) {
    loadingElement.style.display = 'none'
  }
  const appElement = document.getElementById('app')
  if (appElement && appElement.style) {
    appElement.style.display = 'none'
  }
}

const displayApp = function () {
  const loadingElement = document.getElementById('loading')
  if (loadingElement && loadingElement.style) {
    loadingElement.style.display = 'none'
  }
  const appElement = document.getElementById('app')
  if (appElement && appElement.style) {
    appElement.style.display = 'flex'
  }
}

const checkVueComponentLoaded = function () {
  return document.getElementById('app-content')
}

window.onload = () => {
  Promise.all([axios.get('/api/version'), axios.get('/version')])
    .then((values) => {
      const apiVersion = new StoredVersion(
        APP_VERSION_KEY,
        values[0].data.replace(/(\r\n|\n|\r)/gm, '')
      )
      const addInVersion = new StoredVersion(
        ADD_IN_VERSION_KEY,
        values[1].data.replace(/(\r\n|\n|\r)/gm, '')
      )
      apiVersion.persistCurrent()
      addInVersion.persistCurrent()
      if (apiVersion.hasChanged() || addInVersion.hasChanged()) {
        window.location.reload()
      } else {
        window.Office.onReady()
          .then(async () => {
            const i18n = createI18n<[MessageSchema], 'fr'>({
              locale: 'fr',
              fallbackLocale: 'fr',
              messages: {
                fr: fr
              }
            })

            const toastOptions = {
              position: POSITION.TOP_RIGHT,
              transition: 'Vue-Toastification__fade',
              pauseOnHover: true,
              hideProgressBar: true,
              closeOnClick: true,
              timeout: 5000,
              closeButton: ToasterClose,
              containerClassName: 'app-toast-container',
              toastClassName: 'app-toast',
              toastDefaults: {
                [TYPE.SUCCESS]: {
                  timeout: 5000
                },
                [TYPE.ERROR]: {
                  timeout: 5000,
                  hideProgressBar: true
                }
              }
            }

            const loggerOptions: ILoggerOptions = {
              isEnabled: true,
              logLevel: LogLevels.DEBUG,
              stringifyArguments: false,
              showLogLevel: true,
              showMethodName: true,
              separator: '|',
              showConsoleColors: true
            }
            const app = createApp(App)

            app.config.errorHandler = (err) => {
              if(!checkVueComponentLoaded()) {
                displayError()
              }
              Sentry.captureException(err)
            };

            app.use(router)
            app.use(VueLogger, loggerOptions)
            app.use(axiosPlugin)

            // sc-35645: This is a workaround to make i18n work correctly inside async functions
            // cf https://github.com/intlify/vue-i18n/issues/990#issuecomment-1123565067
            app.use({
              install(appInner) {
                appInner.config.globalProperties.$t = i18n.global.t
                appInner.config.globalProperties.$te = i18n.global.te
                appInner.config.globalProperties.$tc = i18n.global.tc
              }
            })

            app.use(await auth0())
            app.use(websocketPlugin)
            app.use(inputFilters)
            app.use(Toast, toastOptions)
            app.use(VueBaseTooltip, { delay: 250 })

            initSentry(app)

            app.mount('#app')

            displayApp()
          })
          .catch(() => {
            displayError()
          })
      }
    })
    .catch(() => {
      displayError()
    })
}
